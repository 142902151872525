import { DropdownIcon } from 'assets/icons/DropdownIcon';
import clsx from 'clsx';
import { Switch } from 'components/Switch';
import { BASE_URL } from 'configs/api';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { INetworkWithCurrencies } from 'types/networks';
import { INetworkStatusForm } from '.';
import { useImgPreloader } from 'hooks/useImagePreload';

interface NetworkStatusItemProps {
  network: INetworkWithCurrencies;
  i: number;
}

export function NetworkStatusItem({ network, i }: NetworkStatusItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const form = useFormContext<INetworkStatusForm>();

  const { imgsPreloaded } = useImgPreloader([
    BASE_URL + network.network_image_url,
    ...network.currencies.map((c) => BASE_URL + c.image_url),
  ]);

  const networkActive = form.watch(`networks.${i}.active`);

  return (
    <div className="inline-flex flex-col">
      <div
        className={clsx(
          'flex items-center text-sm leading-[1.0625rem',
          !networkActive && 'disabled'
        )}
      >
        <div className="relative w-7 min-w-7 h-7 mr-3">
          <div
            className={clsx(
              'absolute inset-0 bg-[#A39DC7] rounded-full',
              networkActive ? 'opacity-0' : 'opacity-100'
            )}
          />
          <img
            src={BASE_URL + network.network_image_url}
            className={clsx(
              'w-7 min-w-7 h-7 transition-all duration-200',
              !networkActive && 'mix-blend-luminosity',
              imgsPreloaded ? 'opacity-100' : 'opacity-0'
            )}
            alt=""
          />
        </div>
        <div
          className={clsx(
            'mr-auto transition-colors duration-200',
            networkActive ? 'text-white' : 'text-[#A39DC7]'
          )}
        >
          {network.name}
        </div>
        <div className="relative flex items-center ml-6 pr-6">
          <Controller
            name={`networks.${i}.active`}
            control={form.control}
            render={({ field }) => (
              <Switch
                checked={field.value}
                onCheckedChange={(val) => {
                  field.onChange(val);
                }}
              />
            )}
          />
          <button
            type="button"
            className="absolute right-0 bg-transparent border-none"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <DropdownIcon className={clsx(isExpanded && 'rotate-180')} />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="flex flex-col gap-4 mt-[0.8125rem] ml-[2.5rem]">
          {network.currencies.map((currency, j) => {
            const active = form.watch(`networks.${i}.currencies.${j}.active`);

            return (
              <div
                className="flex items-center gap-3 text-sm leading-[1.0625rem]"
                key={currency.id}
              >
                <div
                  className={clsx(
                    'flex justify-center items-center w-5 min-w-5 h-5 rounded-full bg-white transition-opacity duration-200',
                    imgsPreloaded ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  <img
                    src={BASE_URL + currency.image_url}
                    className="w-4 min-w-4 h-4"
                    alt=""
                  />
                </div>
                <div
                  className={clsx(
                    'mr-auto transition-colors duration-200',
                    !active || !networkActive ? 'text-[#A39DC7]' : 'text-white'
                  )}
                >
                  {currency.symbol}
                </div>
                <div className="ml-auto mr-6">
                  <Controller
                    name={`networks.${i}.currencies.${j}.active`}
                    control={form.control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onCheckedChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
